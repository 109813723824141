import { Environment, PLAID_SETTINGS } from './environment.model';

export const environment: Environment = {
  production: false,
  apiBaseUrl: 'https://admin-api-qa.fundo.com/api/v1',
  plaidSettings: {
    plaidPublicKey: PLAID_SETTINGS.plaidPublicKey,
    env: 'sandbox',
    clientName: PLAID_SETTINGS.clientName,
    product: PLAID_SETTINGS.product,
  },
  captchaKey: '6Lf5MsMZAAAAAE5JqQ9SXWr8jximjOr7L4cCEW1T',
  googleId: 'UA-157340971-1',
};
